import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

class NotFoundPage extends React.Component {
    constructor(props) {
        super(props)
        this.state={fire:null}
    }
    render() {
        return (<Layout container={true}>
            <SEO title="404: Nicht gefunden" />
            <h1 style={{textAlign:"center",marginTop:130}}>Fehler 404 - Seite nicht gefunden</h1>
            <p style={{textAlign:"center",marginBottom:20}}>Einer aus unserem Entwicklungsteam muss für dieses inakzeptable Versagen bestraft werden!</p>
            <img  style={{margin:"40px auto",display:"block",height:"1.4em"}} src={require("../images/404/choose.png")}/>
            <div className="pick-one">
                <img className={this.state.fire === "domi" ? "fired" : "" } onClick={()=>{this.setState({fire:"domi"})}} src={require("../images/team/domi2.jpg")}/>
                <img className={this.state.fire === "vicky" ? "fired" : "" } onClick={()=>{this.setState({fire:"vicky"})}}  src={require("../images/team/elisa.jpg")}/>
                <img className={this.state.fire === "nico" ? "fired" : "" } onClick={()=>{this.setState({fire:"nico"})}}  src={require("../images/team/ema.jpg")}/>
            </div>
            <div style={{textAlign:"center",marginBottom:80,marginTop:80}}>
                <a className="btn btn-primary-outline" style={{textAlign:"center !important",margin:"auto !important",display:"inline-block !important"}} href="/">
                    <FontAwesomeIcon icon={faHome}/>&nbsp;
                    Zur Startseite
                </a>
            </div>
          </Layout>)
    }
}


export default NotFoundPage
